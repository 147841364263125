<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>  
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Certificadores</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Gerenciamento dos
                  <span>Certificadores</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div
                v-if="exibeTelas.certificadores"
                class="row mt-4"
              >
                <div
                  v-if="fastMsgErro.status"
                  class="col-12 mt-4 text-center"
                >
                  <h6 class="text-danger">
                    {{ fastMsgErro.message }}
                  </h6>
                </div>
                <div
                  v-else
                  class="col-12 mt-2"
                >
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <h6 class="m-0 font-weight-bold text-primary">
                            Lista de certificadores
                          </h6>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                          <button
                            v-if="!fastSecretariaCertificadores.length"
                            class="btn btn-sm btn-primary"
                            @click.prevent="showModal('modalCriarSecretariaCertificador')"
                          >
                            <small>+ Registrar certificador</small>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 table-responsive mt-2">
                          <table class="table table-sm">
                            <thead class="thead-dark">
                              <tr class="text-center">
                                <th>
                                  <small class="font-weight-bold text-center">Nome contato</small>
                                </th>
                                <th>
                                  <small class="font-weight-bold text-center">Data do contrato</small>
                                </th>                               
                                <th>
                                  <small class="font-weight-bold text-center">Status</small>
                                </th>
                                <th class="font-weight-bold text-center">
                                  <small class="font-weight-bold">Ações</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastSecretariaCertificadoresLoading">
                              <tr>
                                <td
                                  colspan="4"
                                  class="text-center"
                                  v-html="fastLoadingDados()"
                                />
                              </tr>
                            </tbody>
                            <tbody v-else-if="fastSecretariaCertificadores.length">
                              <tr
                                v-for="(certificador, index) in fastSecretariaCertificadores"
                                :key="index"
                              >
                                <td class="align-middle text-center">
                                  {{ certificador.nome_contato }}
                                </td>   
                                <td class="align-middle text-center">
                                  <span v-if="certificador.data_contrato">{{ certificador.data_contrato.split("T")[0].split("-")[2] + "/" + certificador.data_contrato.split("T")[0].split("-")[1] + "/" + certificador.data_contrato.split("T")[0].split("-")[0] }}</span>
                                  <span
                                    v-else
                                    class="text-warning"
                                  >
                                    Pendente
                                  </span>
                                </td>                                 
                                <td class="align-middle text-center">
                                  <span
                                    v-if="certificador.ativo"
                                    class="btn btn-sm btn-success pt-0 pb-0"
                                  >Ativado</span>
                                  <span
                                    v-else
                                    class="btn btn-sm btn-danger pt-0 pb-0"
                                  >Desativado</span>
                                </td>                         
                                <td class="text-center align-middle">                                  
                                  <button                                    
                                    class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                    @click="exibeModalEditarSecretariaCertificador(certificador)"
                                  >
                                    <small v-if="certificador.ativo">Detalhes</small>
                                    <small v-else>Editar</small>
                                  </button>
                                  <button
                                    class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                                    @click="exibeModalEditarCursos(certificador)"
                                  >
                                    <small>Cursos <span class="badge badge-light">{{ certificador.total_cursos }}</span></small>
                                  </button>
                                  <button
                                    v-if="!certificador.ativo"
                                    class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                    @click="exibeModalExcluirSecretariaCertificador(certificador)"
                                  >
                                    <small>Excluir</small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="4"
                                  class="text-center"
                                >
                                  Nenhum certificador registrado
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else-if="exibeTelas.cursos"
                class="row mt-4"
              >
                <div class="col-12 mb-4">
                  <h3>Certificador - {{ fastSecretariaCertificadorEditar.nome_contato }}</h3>                  
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <h4>Total de cursos ({{ fastSecretariaCertificadorCursos.length }})</h4>
                </div>
                <div
                  v-if="!fastSecretariaCertificadorEditar.ativo"
                  class="col-sm-12 col-md-12 col-lg-6 text-right"
                >
                  <button
                    class="btn btn-sm btn-primary"
                    @click="showModal('modalMeusCursos')"
                  >
                    + Adicionar curso
                  </button>
                </div>
                <div class="col-12 mt-2">
                  <div
                    v-if="fastSecretariaCertificadorCursosLoading"
                    class="row justify-content-around text-center"
                  >
                    <p>Carregando...</p>
                  </div>
                  <div
                    v-else-if="fastSecretariaCertificadorCursos.length"
                    class="row"
                  >
                    <div
                      v-for="curso in fastSecretariaCertificadorCursos"
                      :key="curso.id_secretaria_curso"
                      class="col-sm-12 col-md-12 col-lg-4 mb-4"
                    >
                      <div class="card">
                        <div class="card-header text-center">
                          <b class="card-title">{{ curso.nome_curso }}</b>
                        </div>
                        <img
                          v-if="curso.logo_curso"
                          class="card-img-top"
                          :src="ajustaLinkApiAntiga(curso.logo_curso)"
                          alt="Logo do curso"
                        >
                        <img
                          v-else
                          class="card-img-top"
                          :src="require('@/assets/images/app/plataforma-img.jpg')"
                          alt="Logo do curso"
                        >
                        <div class="card-body text-center">   
                          <button
                            class="btn btn-sm btn-primary ml-1 mr-1"
                            @click="exibeModalSecretariaCertificadorAnexo(curso)"
                          >
                            <small>
                              Anexo 
                              <span
                                v-if="curso.path_portaria && curso.path_portaria != '0'"
                                class="badge badge-light"
                              >1</span> 
                              <span
                                v-else
                                class="badge badge-light"
                              >0</span>
                            </small>                              
                          </button>
                          <button
                            class="btn btn-sm btn-primary ml-1 mr-1"
                            @click="exibeModalSecretariaCertificadorEstado(curso)"
                          >
                            <small>
                              Estados <span class="badge badge-light">{{ curso.total_estados }}</span>
                            </small>                            
                          </button>
                          <button
                            class="btn btn-sm btn-primary ml-1 mr-1"
                            @click="exibeModalSecretariaCertificadorPreco(curso)"
                          >
                            <small>
                              Preços <span class="badge badge-light">{{ curso.total_precos }}</span>
                            </small>
                          </button> 
                        </div>
                        <div class="card-footer text-center">
                          <button
                            v-if="!fastSecretariaCertificadorEditar.ativo"
                            class="btn btn-sm btn-danger pt-0 pb-0"
                            @click="exibeModalExcluirSecretariaCertificadorCurso(curso)"
                          >
                            <small>- Remover curso</small>                    
                          </button>
                        </div>
                      </div>
                    </div>                              
                  </div>
                  <div
                    v-else
                    class="row justify-content-around text-center"
                  >
                    <p>Nenhum curso cadastrado</p>
                  </div> 
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  v-if="exibeTelas.certificadores"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
                <button
                  v-else
                  class="btn btn-red-hollow"
                  @click="exibeTelas.cursos = false; exibeTelas.certificadores = true"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarSecretariaCertificador"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo certificador</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarSecretariaCertificador')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Nome contato</label>
              <input
                v-model="fastSecretariaCertificadorNovo.nome_contato"
                type="text"
                class="form-control"
                maxlength="100"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Fone contato</label>
              <input
                v-model="fastSecretariaCertificadorNovo.fone_contato"
                v-mask="'(##) #########'"
                type="text"
                class="form-control"
                maxlength="20"
              >
            </div>            
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarSecretariaCertificador()"
                >
                  Registrar certificador
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarSecretariaCertificador"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar certificador</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarSecretariaCertificador')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Nome contato</label>
              <input
                v-model="fastSecretariaCertificadorEditar.nome_contato"
                type="text"
                class="form-control"
                maxlength="100"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Fone contato</label>
              <input
                v-model="fastSecretariaCertificadorEditar.fone_contato"
                v-mask="'(##) #########'"
                type="text"
                class="form-control"
                maxlength="20"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Data do contrato</label>
              <input
                :value="fastSecretariaCertificadorEditar.data_contrato"
                type="date"
                class="form-control"
                readonly
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Data da ativação do contrato</label>
              <input
                :value="fastSecretariaCertificadorEditar.data_ativacao_contrato"
                type="date"
                class="form-control"
                readonly
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Data do encerramento do contrato</label>
              <input
                :value="fastSecretariaCertificadorEditar.data_encerramento_contrato"
                type="date"
                class="form-control"
                readonly
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarSecretariaCertificador()"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirSecretariaCertificador"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir certificador?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirSecretariaCertificador')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirSecretariaCertificador()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalSecretariaCertificadorCursos"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Cursos certificados ({{ fastSecretariaCertificadorCursos.length }})</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalSecretariaCertificadorCursos')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <button
            class="btn btn-sm btn-primary"
            @click="showModal('modalMeusCursos', 'modalSecretariaCertificadorCursos')"
          >
            + Adicionar curso
          </button>
        </div>
        <div class="col-12 pl-4 pr-4">
          <div
            v-if="fastSecretariaCertificadorCursosLoading"
            class="row justify-content-around text-center"
          >
            <p>Carregando...</p>
          </div>
          <div
            v-else-if="fastSecretariaCertificadorCursos.length"
            class="row"
          >
            <div
              v-for="curso in fastSecretariaCertificadorCursos"
              :key="curso.id_secretaria_curso"
              class="col-sm-12 col-md-12 col-lg-6 mb-4"
            >
              <div class="card">
                <img
                  v-if="curso.logo_curso"
                  class="card-img-top"
                  :src="ajustaLinkApiAntiga(curso.logo_curso)"
                  alt="Logo do curso"
                >
                <img
                  v-else
                  class="card-img-top"
                  :src="require('@/assets/images/app/plataforma-img.jpg')"
                  alt="Logo do curso"
                >
                <div class="card-body text-center">
                  <h6 class="card-title">
                    {{ curso.nome_curso }}
                  </h6>
                  <div v-if="curso.path_portaria && curso.path_portaria != '0'">
                    <a
                      :href="curso.path_portaria"
                      target="_blank"
                      download
                    >
                      Download do anexo
                    </a>
                  </div>
                  <div
                    v-show="(fastSecretariaCertificadorCursoEditar.uploadEmExecucao && fastSecretariaCertificadorCursoEditar.id_secretaria_curso == curso.id_secretaria_curso) || (!fastSecretariaCertificadorCursoEditar.uploadEmExecucao)"
                    class="mt-2"
                  >
                    <uploader
                      :file-status-text="statusText"
                      :options="optionsArquivo"
                      class="uploader-example"
                      @file-success="fileSuccess"
                      @file-added="fileValidation(curso.id_secretaria_curso)"
                    >
                      <uploader-unsupport />
                      <uploader-drop>
                        <p
                          v-if="curso.path_portaria && curso.path_portaria != '0'"
                          class="aluno_font_color"
                        >
                          Atualizar anexo
                        </p>
                        <p
                          v-else
                          class="aluno_font_color"
                        >
                          Inserir anexo
                        </p>
                        <uploader-btn
                          :attrs="restrictArquivo"
                        >
                          Clique aqui para selecionar
                        </uploader-btn>
                      </uploader-drop>
                      <uploader-list />
                    </uploader> 
                  </div>
                  <div class="table-responsive mt-2">
                    <table class="table table-sm table-striped">
                      <thead class="thead-dark">
                        <tr class="text-center">
                          <th>Estado</th>
                          <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-center">
                          <td colspan="2">
                            Nenhum estado registrado
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="table-responsive mt-2">
                    <table class="table table-sm table-striped">
                      <thead class="thead-dark">
                        <tr class="text-center">
                          <th>Quantidade mensal</th>
                          <th>Preço</th>
                          <th>Comissão FastEAD</th>
                          <th>Dias de repasse</th>
                          <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-center">
                          <td colspan="5">
                            Nenhum preço registrado
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer text-center">
                  <button
                    class="btn btn-sm btn-danger"
                    @click="exibeModalExcluirSecretariaCertificadorCurso(curso)"
                  >
                    <small>- Remover curso</small>                    
                  </button>
                </div>
              </div>
            </div>                              
          </div>
          <div
            v-else
            class="row justify-content-around text-center"
          >
            <p>Nenhum curso cadastrado</p>
          </div> 
        </div>
      </div>
    </modal>

    <modal
      name="modalMeusCursos"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="ol-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Lista de cursos</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalMeusCursos')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Filtro por nome do curso"
                @keyup="filtroNomeCurso"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-2 mb-1">
              <select
                v-model="filtroNivelEscolhido"
                class="form-control form-control-sm"
                @change="aplicaFiltroCursos()"
              >
                <option value="">
                  -- Filtrar por nível --
                </option>
                <option
                  v-for="(nivel, index) in filtroNiveis"
                  :key="index"
                  :value="nivel"
                >
                  <span>{{ retornaCursoNivel(nivel) }}</span>
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th>
                      <small
                        class="font-weight-bold"
                      >Nome do curso</small>
                    </th>
                    <th>
                      <small class="font-weight-bold">Nível</small>                                  
                    </th>
                    <th class="text-center">
                      <small
                        class="font-weight-bold"
                      >Carga horária</small>
                    </th>
                    <th class="text-center">
                      <small
                        class="font-weight-bold"
                      >Preço</small>
                    </th>
                    <th class="text-center">
                      <small
                        class="font-weight-bold"
                      >Publicado</small>
                    </th>
                    <th class="text-center">
                      <small class="font-weight-bold">Ações</small>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="fastSecretariaCursosPaginacao.length">
                  <tr
                    v-for="curso in fastSecretariaCursosPaginacao"
                    :key="curso.id_curso"
                  >
                    <td class="align-middle">
                      <small>{{ curso.nome_curso }}</small>  
                    </td>  
                    <td class="align-middle">
                      <small>{{ retornaCursoNivel(curso.nivel) }}</small> 
                    </td>  
                    <td class="align-middle text-center">
                      <span v-if="curso.carga_horaria">
                        <small>{{ curso.carga_horaria }}h</small>                                    
                      </span>                                  
                    </td> 
                    <td class="align-middle text-center">
                      <small v-if="curso.preco">R$ {{ formataPreco(curso.preco) }}</small>
                      <small
                        v-else
                        class="text-danger"
                      >Indefinido</small>                           
                    </td> 
                    <td class="align-middle text-center">
                      <span
                        v-if="curso.publicado"
                        class="btn btn-sm btn-success pt-0 pb-0"
                      >
                        <small>Sim</small>
                      </span>
                      <span
                        v-else
                        class="btn btn-sm btn-danger pt-0 pb-0"
                      >
                        <small>Não</small>
                      </span>
                    </td>   
                    <td class="align-middle text-center">
                      <span
                        v-if="!verificaCursoJaInserido(curso.id_secretaria_curso)"
                        class="btn btn-sm btn-success text-white pt-0 pb-0"
                      >
                        <small @click="inserirSecretariaCertificadorCurso(curso)">+ Adicionar curso</small>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="6"
                    >
                      Nenhum curso encontrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="10"
                :items="fastSecretariaCursosFiltro"
                @changePage="fastSecretariaCursosPaginacao = $event"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalMeusCursos')"
              >
                Voltar
              </a>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirSecretariaCertificadorCurso"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Excluir curso do certificador?</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirSecretariaCertificadorCurso')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mb-4">
              <h6>{{ fastSecretariaCertificadorCursoEditar.nome_curso }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger mr-2"
                  @click.prevent="excluirSecretariaCertificadorCurso()"
                >
                  Confirmar
                </button>
                <button
                  href="#"
                  type="button"
                  class="btn btn-secondary mr-2"
                  @click.prevent="hideModal('modalExcluirSecretariaCertificadorCurso')"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>          
        </div>
      </div>
    </modal>

    <modal
      name="modalSecretariaCertificadorAnexo"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Anexo</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalSecretariaCertificadorAnexo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div v-if="fastSecretariaCertificadorCursoEditar.path_portaria && fastSecretariaCertificadorCursoEditar.path_portaria != '0'">
            <a
              :href="fastSecretariaCertificadorCursoEditar.path_portaria"
              target="_blank"
              download
            >
              Download do anexo
            </a>
          </div>
          <div
            v-show="(fastSecretariaCertificadorCursoEditar.uploadEmExecucao && fastSecretariaCertificadorCursoEditar.id_secretaria_curso == curso.id_secretaria_curso) || (!fastSecretariaCertificadorCursoEditar.uploadEmExecucao)"
            class="mt-2"
          >
            <uploader
              :file-status-text="statusText"
              :options="optionsArquivo"
              class="uploader-example"
              @file-success="fileSuccess"
              @file-added="fileValidation(fastSecretariaCertificadorCursoEditar.id_secretaria_curso)"
            >
              <uploader-unsupport />
              <uploader-drop>
                <p
                  v-if="fastSecretariaCertificadorCursoEditar.path_portaria && fastSecretariaCertificadorCursoEditar.path_portaria != '0'"
                  class="aluno_font_color"
                >
                  Atualizar anexo
                </p>
                <p
                  v-else
                  class="aluno_font_color"
                >
                  Inserir anexo
                </p>
                <uploader-btn
                  :attrs="restrictArquivo"
                >
                  Clique aqui para selecionar
                </uploader-btn>
              </uploader-drop>
              <uploader-list />
            </uploader> 
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalSecretariaCertificadorEstado"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Estados</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalSecretariaCertificadorEstado')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 text-right">
              <button
                v-if="fastSecretariaCertificadorEstados.length < 27 && !fastSecretariaCertificadorEditar.ativo"
                class="btn btn-sm btn-primary"
                @click="showModal('modalListaEstados', 'modalSecretariaCertificadorEstado')"
              >
                <small>+ Adicionar estado</small>
              </button>
            </div>
          </div>
          <div class="table-responsive mt-2">
            <table class="table table-sm table-striped">
              <thead class="thead-dark">
                <tr class="text-center">
                  <th>Estado</th>
                  <th>Ações</th>
                </tr>
              </thead>     
              <tbody v-if="fastSecretariaCertificadorEstadosLoading">
                <tr class="text-center">
                  <td colspan="2">
                    Carregando estados...
                  </td>
                </tr>
              </tbody>      
              <tbody v-else-if="fastSecretariaCertificadorEstados.length">
                <tr
                  v-for="estado in fastSecretariaCertificadorEstados"
                  :key="estado.uf"
                  class="text-center"
                >
                  <td>{{ retornaEstadoNome(estado.uf) }}</td>
                  <td>
                    <button
                      v-if="!fastSecretariaCertificadorEditar.ativo"
                      class="btn btn-sm btn-danger pt-0 pb-0"
                      @click="excluirSecretariaCertificadorEstado(estado.uf)"
                    >
                      <small>- Remover</small>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="text-center">
                  <td colspan="2">
                    Nenhum estado registrado
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalListaEstados"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Lista de estados</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalSecretariaCertificadorEstado', 'modalListaEstados')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 text-right">
              <button
                class="btn btn-sm btn-success pt-0 pb-0"
                @click="inserirSecretariaCertificadorEstado('00')"
              >
                <small>+ Adicionar todos</small>
              </button>
            </div>
          </div>
          <div class="table-responsive mt-2">
            <table class="table table-sm table-striped">
              <thead class="thead-dark">
                <tr class="text-center">
                  <th>Estado</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="estado in listaEstados"
                  :key="estado.sigla"
                  class="text-center"
                >
                  <td>{{ estado.nome }}</td>
                  <td>
                    <button
                      v-if="!verificaEstadoJaInserido(estado.sigla)"
                      class="btn btn-sm btn-success pt-0 pb-0"
                      @click="inserirSecretariaCertificadorEstado(estado.sigla)"
                    >
                      <small>+ Adicionar</small>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-12">
              <button
                class="btn btn-secondary"
                @click="showModal('modalSecretariaCertificadorEstado', 'modalListaEstados')"
              >
                Voltar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalSecretariaCertificadorPreco"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Preços</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalSecretariaCertificadorPreco')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 text-right" />
          </div>
          <div class="table-responsive mt-2">
            <table class="table table-sm table-striped">
              <thead class="thead-dark">
                <tr class="text-center">
                  <th>Quantidade mensal</th>
                  <th>Preço</th>
                  <th>Comissão FastEAD</th>
                  <th>Dias de repasse</th>
                </tr>
              </thead>
              <tbody v-if="fastSecretariaCertificadorPrecosLoading">
                <tr class="text-center">
                  <td colspan="4">
                    Carregando preços...
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="fastSecretariaCertificadorPrecos.length">
                <tr
                  v-for="(preco, index) in fastSecretariaCertificadorPrecos"
                  :key="index"
                  class="text-center"
                >
                  <td>{{ preco.qtde_mensal }}</td>
                  <td>
                    <span v-if="preco.preco">R$ {{ formataPreco(preco.preco) }}</span>
                  </td>
                  <td>
                    <span v-if="preco.comissao_fastead">R$ {{ formataPreco(preco.comissao_fastead) }}</span>
                  </td>
                  <td>{{ preco.dias_repasse_pagto }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="text-center">
                  <td colspan="4">
                    Nenhum preço registrado
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </modal>

    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoSecretariaCertificadores",
  components: {
    Pagination
  },

  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      exibeTelas: {
        certificadores: true,
        cursos: false
      },
      fastSecretariaCertificadores: [],
      fastSecretariaCertificadoresLoading: true,
      fastSecretariaCertificadorNovo: {
        id_pessoa: 0,
	      id_plataforma_mae: 0,
	      nome_contato: "",
	      fone_contato: "",
	      data_contrato: "",
        id_usuario_cadastro: "",
        ativo: "",
        data_ativacao_contrato: "",
        data_encerramento_contrato: "",
      },
      fastSecretariaCertificadorEditar: {
        id_pessoa: 0,
	      id_plataforma_mae: 0,
	      nome_contato: "",
	      fone_contato: "",
	      data_contrato: "",
        id_usuario_cadastro: "",
        ativo: "",
        data_ativacao_contrato: "",
        data_encerramento_contrato: "",
      },
      // Erros
      fastMsgErro: {
        status: false,
        message: ""
      },
      // Cursos
      fastSecretariaCertificadorCursos: [],
      fastSecretariaCertificadorCursosLoading: true,      
      fastSecretariaCertificadorCurso: {
        id_pessoa: 0,
        id_plataforma_mae: "",
        id_secretaria_curso: 0,
        path_portaria: ""
      },
      fastSecretariaCertificadorCursoEditar: {
        id_pessoa: 0,
        id_plataforma_mae: "",
        id_secretaria_curso: 0,
        path_portaria: "",
        uploadEmExecucao: 0
      },      
      fastSecretariaCursosTotal: [],
      fastSecretariaCursosFiltro: [],
      fastSecretariaCursosPaginacao: [],
      // Filtros
      filtroNiveis: [],
      filtroNivelEscolhido: "",
      // Upload arquivo
      optionsArquivo: {
        target:
          settings.endApiFastEad + "api/fast_plataforma_certificador_curso/upload",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: true,
        query: {
          id_plataforma: this.$route.params.id_plataforma
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: [
            "pdf",
            "odt",
            "doc",
            "docx",
            "ppt",
            "pptx",
            "gif",
            "jpg",
            "jpeg",
            "png",
          ],
        },
        parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivo: {
        accept:
          "application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/vnd.ms-powerpoint,image/*",
      },
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
      // Estados
      fastSecretariaCertificadorEstados: [],
      fastSecretariaCertificadorEstadosLoading: true,
      fastSecretariaCertificadorEstado: {
        id_pessoa: 0,
        id_plataforma_mae: "",
        id_secretaria_curso: 0,
        uf: ""
      },
      fastSecretariaCertificadorEstadoEditar: {
        id_pessoa: 0,
        id_plataforma_mae: "",
        id_secretaria_curso: 0,
        uf: ""
      },  
      listaEstados: [
        {nome: "Acre", sigla: "AC"},
        {nome: "Alagoas", sigla: "AL"},
        {nome: "Amapá", sigla: "AP"},
        {nome: "Amazonas", sigla: "AM"},
        {nome: "Bahia", sigla: "BA"},
        {nome: "Ceará", sigla: "CE"},
        {nome: "Distrito Federal", sigla: "DF"},
        {nome: "Espírito Santo", sigla: "ES"},
        {nome: "Goiás", sigla: "GO"},
        {nome: "Maranhão", sigla: "MA"},
        {nome: "Mato Grosso", sigla: "MT"},
        {nome: "Mato Grosso do Sul", sigla: "MS"},
        {nome: "Minas Gerais", sigla: "MG"},
        {nome: "Pará", sigla: "PA"},
        {nome: "Paraíba", sigla: "PB"},
        {nome: "Paraná", sigla: "PR"},
        {nome: "Pernambuco", sigla: "PE"},
        {nome: "Piauí", sigla: "PI"},
        {nome: "Rio de Janeiro", sigla: "RJ"},
        {nome: "Rio Grande do Norte", sigla: "RN"},
        {nome: "Rio Grande do Sul", sigla: "RS"},
        {nome: "Rondônia", sigla: "RO"},
        {nome: "Roraima", sigla: "RR"},
        {nome: "Santa Catarina", sigla: "SC"},
        {nome: "São Paulo", sigla: "SP"},
        {nome: "Sergipe", sigla: "SE"},
        {nome: "Tocantins", sigla: "TO"}
      ],
      // Preços
      fastSecretariaCertificadorPrecos: [],
      fastSecretariaCertificadorPrecosLoading: true,
      fastSecretariaCertificadorPreco: {
        id_certificador_preco: "",
        id_pessoa: 0,
        id_plataforma_mae: "",
        id_secretaria_curso: 0,
        qtde_mensal: "",
        preco: "",
        comissao_fastead: "",
        dias_repasse_pagto: ""
      },
      fastSecretariaCertificadorPrecoEditar: {
        id_certificador_preco: "",
        id_pessoa: 0,
        id_plataforma_mae: "",
        id_secretaria_curso: 0,
        qtde_mensal: "",
        preco: "",
        comissao_fastead: "",
        dias_repasse_pagto: ""
      },  
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.getSecretariaCertificadores(this.$route.params.id_plataforma);
            this.getSecretariaCursos(this.$route.params.id_plataforma);
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    getSecretariaCertificadores(id_plataforma){
      this.fastSecretariaCertificadoresLoading = true
      this.promiseGetFastApi("api/fast_plataforma_parametro/lista", "id_plataforma=" + id_plataforma).then(obj => {
        return obj;
      }).then(obj => {
        console.log(obj)
        if (obj.length) {
          if (obj[0].id_pessoa) {
            this.fastSecretariaCertificadorNovo.id_pessoa = obj[0].id_pessoa
            this.fastSecretariaCertificadorNovo.id_plataforma_mae = parseInt(id_plataforma)
            this.promiseGetFastApi("api/fast_plataforma_certificador/lista", "id_plataforma=" + id_plataforma + "&id_pessoa=" + obj[0].id_pessoa ).then(obj => {
              if (obj.length) this.fastSecretariaCertificadores = obj;
              else
              this.fastSecretariaCertificadores = []

              this.fastSecretariaCertificadoresLoading = false
            }).catch(e => {
              this.exibeToasty("Não foi possível listar o certificadores", "error");
              this.fastSecretariaCertificadoresLoading = false
            });
          } else {
            this.fastSecretariaCertificadoresLoading = false
            this.exibeToasty("Não é possível listar o certificadores", "error");
            this.fastMsgErro.status = true;
            this.fastMsgErro.message = "Sua plataforma não possui identificador de pessoa. Consulte um administrador para cadastrá-lo."
          }
        } else {
          this.fastSecretariaCertificadoresLoading = false
          this.exibeToasty("Não é possível listar o certificadores", "error");
          this.fastMsgErro.message = "Sua plataforma não possui identificador de pessoa. Consulte um administrador para cadastrá-lo."
        }
      }).catch(e => {
        this.fastSecretariaCertificadoresLoading = false
        console.log(e);
      })      
    },
    async criarSecretariaCertificador() {
      let erros = []

      if (!this.fastSecretariaCertificadorNovo.nome_contato) erros.push("O nome é obrigatório")
      if (!this.fastSecretariaCertificadorNovo.fone_contato) erros.push("O telefone é obrigatório")

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.promiseInserirFastApi(this.fastSecretariaCertificadorNovo, "fast_plataforma_certificador").then((res) => {
          console.log("criarSecretariaCertificador", res)
          this.exibeToasty("Certificador registrado com sucesso", "success");
          this.hideModal("modalCriarSecretariaCertificador");        
          this.fastSecretariaCertificadores = res;
          this.fastSecretariaCertificadorNovo = {
            id_pessoa: 0,
            id_plataforma_mae: 0,
            nome_contato: "",
            fone_contato: "",
            data_contrato: "",
            id_usuario_cadastro: "",
            ativo: "",
            data_ativacao_contrato: "",
            data_encerramento_contrato: "",
          }
        }).catch((e) => {
          this.exibeToasty("Erro ao registrar certificador", "error");
        });
      }
    },
    exibeModalEditarSecretariaCertificador(certificador) {
      if (certificador.data_contrato) certificador.data_contrato = certificador.data_contrato.split("T")[0]
      if (certificador.data_ativacao_contrato) certificador.data_ativacao_contrato = certificador.data_ativacao_contrato.split("T")[0]
      if (certificador.data_encerramento_contrato) certificador.data_encerramento_contrato = certificador.data_encerramento_contrato.split("T")[0]
      this.fastSecretariaCertificadorEditar = certificador;
      this.showModal("modalEditarSecretariaCertificador");
    },
    async editarSecretariaCertificador() {
       this.$store.state.fastCarregando = true;
        this.promiseAtualizarFastApi(this.fastSecretariaCertificadorEditar, "fast_plataforma_certificador").then(e => {
          this.exibeToasty("Salvo com sucesso", "success");
          this.$store.state.fastCarregando = false;
          this.hideModal("modalEditarSecretariaCertificador");
        }).catch(e => {
          this.exibeToasty("Erro ao salvar alterações", "error");
          this.$store.state.fastCarregando = false;
        }
        );
    },
    exibeModalExcluirSecretariaCertificador(certificador) {      
      this.fastSecretariaCertificadorEditar = certificador;      
      this.showModal("modalExcluirSecretariaCertificador");      
    },
    async excluirSecretariaCertificador(){
      this.promiseExcluirFastApi(this.fastSecretariaCertificadorEditar, "fast_plataforma_certificador").then(e => {
        this.exibeToasty("Certificador excluído com sucesso", "success");  
        this.hideModal("modalExcluirSecretariaCertificador");
        this.getSecretariaCertificadores(this.$route.params.id_plataforma);
      }).catch(e => {
        this.exibeToasty("Erro ao excluir", "error");
        this.$store.state.fastCarregando = false;
      });
    },
    exibeModalEditarCursos(certificador){
      this.fastSecretariaCertificadorEditar = certificador;  
      this.fastSecretariaCertificadorCursosLoading = true
      //this.showModal('modalSecretariaCertificadorCursos')
      this.exibeTelas.certificadores = false
      this.exibeTelas.cursos = true
      this.promiseGetFastApi("api/fast_plataforma_certificador_curso/lista", "id_plataforma=" + certificador.id_plataforma_mae + "&id_pessoa=" + certificador.id_pessoa).then(res => {
        this.fastSecretariaCertificadorCursos = res
        this.fastSecretariaCertificadorCursosLoading = false
      }).catch(e => {
        this.fastSecretariaCertificadorCursosLoading = false
        this.exibeToasty(e, "error")
      });
    },
    getSecretariaCursos(id_plataforma){
      this.promiseGetFastApi("api/fast_secretaria_curso/lista", "id_plataforma=" + id_plataforma + "&id_secretaria_curso=0").then(res => {
        console.log(res)
        if (res.length) {  
          this.fastSecretariaCursosTotal = res;
          this.fastSecretariaCursosFiltro = res;
          this.carregaFiltroCursos(res);
        } else {
          this.fastSecretariaCursosTotal = [];
          this.fastSecretariaCursosFiltro = [];
        }
        
        this.$store.state.fastCarregando = false;
      }).catch(e => {
        console.log(e);
      })
    },
    retornaCursoNivel(nivel) {
      switch (nivel) {
        case 'F':
          return 'Ensino fundamental'
          break;
        case 'M':
          return 'Ensino médio'
          break;
        case 'J':
          return 'EJA'
          break;
        case 'T':
          return 'Técnico'
          break;        
        case 'S':
          return 'Ensino superior'
          break;
        case 'P':
          return 'Pós-graduação'
          break;
        case 'M':
          return 'Mestrado'
          break;
        case 'D':
          return 'Doutorado'
          break;        
        default:
          break;
      }
    },
    filtroNomeCurso(e) {
      let text = e.target.value;
      this.fastSecretariaCursosFiltro = this.fastSecretariaCursosTotal.filter(
        (e) => {
          return (
            e.nome_curso.toLowerCase().indexOf(text.toLowerCase()) != "-1"
          );
        }
      );
    },
    aplicaFiltroCursos() {
      this.fastSecretariaCursosFiltro = this.fastSecretariaCursosTotal.filter(
        (e) => {
          if (this.filtroNivelEscolhido) {
            return e.nivel == this.filtroNivelEscolhido;
          } else {
            return true;
          }
        }
      );
    },
    carregaFiltroCursos(obj) {
      this.filtroNiveis = []      
      obj.forEach((e) => {
        this.filtroNiveis.push(e.nivel);
      });
      // Remove valores duplicados do array
      this.filtroNiveis = this.filtroNiveis.filter(
        (a, b) => this.filtroNiveis.indexOf(a) === b
      );
    },
    async inserirSecretariaCertificadorCurso(curso) {
      this.fastSecretariaCertificadorCurso.id_secretaria_curso = curso.id_secretaria_curso
      this.fastSecretariaCertificadorCurso.id_pessoa = this.fastSecretariaCertificadorEditar.id_pessoa
      this.fastSecretariaCertificadorCurso.id_plataforma_mae = this.fastSecretariaCertificadorEditar.id_plataforma_mae
      this.fastSecretariaCertificadorCurso.path_portaria = "0"
      this.fastSecretariaCertificadorCursosLoading = true
      this.promisePostFastApi(this.fastSecretariaCertificadorCurso, "api/fast_plataforma_certificador_curso/insere").then(res => {
        this.fastSecretariaCertificadorCursos = res
        this.fastSecretariaCertificadorCursosLoading = false
        this.hideModal('modalMeusCursos')
      }).catch(e => {
        this.fastSecretariaCertificadorCursosLoading = false
        this.exibeToasty(e, "error")
      });
    },
    verificaCursoJaInserido(id_secretaria_curso){
      let count = 0      
      console.log("id_secretaria_curso", id_secretaria_curso, this.fastSecretariaCertificadorCursos)
      this.fastSecretariaCertificadorCursos.forEach(c => {
        if (c.id_secretaria_curso == id_secretaria_curso) count++
      })
      return count
    },
    fileValidation(id_secretaria_curso) {
      console.log("id_secretaria_curso", id_secretaria_curso)
    },
    fileSuccess(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      this.fastSecretariaCertificadorCursoEditar.uploadEmExecucao = false
      if (!result.error) {
        this.fastSecretariaCertificadorCursosLoading = true
        this.fastSecretariaCertificadorCursoEditar.path_portaria = result.url;
        this.promisePostFastApi(this.fastSecretariaCertificadorCursoEditar, "api/fast_plataforma_certificador_curso/atualiza").then(res => {
          console.log("atualizaSecretariaCertificadorCurso", res)
          this.fastSecretariaCertificadorCursos = res
          this.fastSecretariaCertificadorCursosLoading = false     
          this.hideModal('modalSecretariaCertificadorAnexo')     
        }).catch(e => {
          this.fastSecretariaCertificadorCursosLoading = false
          this.exibeToasty(e, "error")
        });
      }
    },
    exibeModalExcluirSecretariaCertificadorCurso(curso) {
      curso.uploadEmExecucao = false
      this.fastSecretariaCertificadorCursoEditar = curso
      this.showModal('modalExcluirSecretariaCertificadorCurso')
    },
    async excluirSecretariaCertificadorCurso(){
      this.fastSecretariaCertificadorCursosLoading = true
      this.fastSecretariaCertificadorCursos = []
      this.promisePostFastApi(this.fastSecretariaCertificadorCursoEditar, "api/fast_plataforma_certificador_curso/exclui").then(res => {
        this.exibeToasty("Curso excluído com sucesso", "success");  
        this.hideModal('modalExcluirSecretariaCertificadorCurso')
        this.fastSecretariaCertificadorCursos = res
        this.fastSecretariaCertificadorCursosLoading = false;
      }).catch(e => {
        this.exibeToasty("Erro ao excluir", "error");
        this.fastSecretariaCertificadorCursosLoading = false;
      });
    },
    exibeModalSecretariaCertificadorAnexo(curso) {
      curso.uploadEmExecucao = false
      this.fastSecretariaCertificadorCursoEditar = curso
      this.showModal('modalSecretariaCertificadorAnexo')      
    },
    exibeModalSecretariaCertificadorEstado(curso) {
      this.fastSecretariaCertificadorCursoEditar = curso
      this.showModal('modalSecretariaCertificadorEstado')
      this.fastSecretariaCertificadorEstadosLoading = true
      this.fastSecretariaCertificadorEstados = []
      this.promiseGetFastApi("api/fast_plataforma_certificador_estado/lista", "id_plataforma=" + this.fastSecretariaCertificadorCursoEditar.id_plataforma_mae + "&id_pessoa=" + this.fastSecretariaCertificadorCursoEditar.id_pessoa + "&id_secretaria_curso=" + curso.id_secretaria_curso).then(res => {
        console.log("exibeModalSecretariaCertificadorEstado", res)
        this.fastSecretariaCertificadorEstados = res
        this.fastSecretariaCertificadorEstadosLoading = false
      }).catch(e => {
        this.fastSecretariaCertificadorEstadosLoading = false
        this.exibeToasty(e, "error")
      });
    },    
    async inserirSecretariaCertificadorEstado(sigla) {
      this.fastSecretariaCertificadorCursoEditar.uf = sigla
      this.promisePostFastApi(this.fastSecretariaCertificadorCursoEditar, "api/fast_plataforma_certificador_estado/insere").then(res => {
        this.fastSecretariaCertificadorEstados = res
        this.fastSecretariaCertificadorCursos.forEach(c => {
          if (c.id_secretaria_curso == this.fastSecretariaCertificadorCursoEditar.id_secretaria_curso) {
            if (this.fastSecretariaCertificadorCursoEditar.uf == '00') c.total_estados = 27
            else
            c.total_estados++
          }
        })
        if (this.fastSecretariaCertificadorCursoEditar.uf == '00') this.showModal('modalSecretariaCertificadorEstado', 'modalListaEstados')
      }).catch(e => {
        this.exibeToasty(e, "error")
      });
    },
    verificaEstadoJaInserido(sigla){
      let count = 0      
      this.fastSecretariaCertificadorEstados.forEach(c => {
        if (c.uf == sigla) count++
      })
      return count
    },
    retornaEstadoNome(sigla) {
      let estado = ""
      this.listaEstados.forEach(e => { if (e.sigla == sigla) estado = e.nome })
      return estado
    },
    async excluirSecretariaCertificadorEstado(sigla) {     
      this.fastSecretariaCertificadorCursoEditar.uf = sigla
      console.log("this.fastSecretariaCertificadorCursoEditar", this.fastSecretariaCertificadorCursoEditar)
      this.fastSecretariaCertificadorEstadosLoading = true
      this.promisePostFastApi(this.fastSecretariaCertificadorCursoEditar, "api/fast_plataforma_certificador_estado/exclui").then(res => {  
        console.log(res)      
        this.exibeToasty("Estado excluído com sucesso", "success");  
        this.fastSecretariaCertificadorEstados = res
        this.fastSecretariaCertificadorEstadosLoading = false 
        
        this.fastSecretariaCertificadorCursos.forEach(c => {
          if (c.id_secretaria_curso == this.fastSecretariaCertificadorCursoEditar.id_secretaria_curso) {
            c.total_estados--
          }
        })
      }).catch(e => {
        this.exibeToasty("Erro ao excluir", "error");
        this.fastSecretariaCertificadorEstadosLoading = false;
      });
    },
    // Preço
    exibeModalSecretariaCertificadorPreco(curso) {
      this.fastSecretariaCertificadorPrecosLoading = true
      this.fastSecretariaCertificadorCursoEditar = curso
      this.showModal('modalSecretariaCertificadorPreco')      
      this.fastSecretariaCertificadorPrecos = []
      this.promiseGetFastApi("api/fast_plataforma_certificador_preco/lista", "id_plataforma=" + this.fastSecretariaCertificadorCursoEditar.id_plataforma_mae + "&id_pessoa=" + this.fastSecretariaCertificadorCursoEditar.id_pessoa + "&id_secretaria_curso=" + this.fastSecretariaCertificadorCursoEditar.id_secretaria_curso).then(res => {
        this.fastSecretariaCertificadorPrecos = res
        this.fastSecretariaCertificadorPrecosLoading = false
      }).catch(e => {
        this.fastSecretariaCertificadorPrecosLoading = false
        this.exibeToasty(e, "error")
      });
    },
    exibeModalEditarSecretariaCertificadorPreco(preco){
      this.fastSecretariaCertificadorPrecoEditar = preco
      this.showModal('modalEditarSecretariaCertificadorPreco', 'modalSecretariaCertificadorPreco')
    },
  },
};
</script>

<style scope>
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
